export function mapSurveyIcon(param) {
  return mapping[param];
}

const mapping = {
  year_born: "score_demographics.svg",
  retirement_age: "score_calendar.svg",
  social_security_start_age: "score_government.svg",
  married: "score_married.svg",
  gender: "score_gender.svg",
  zipcode: "score_zipcode.svg",
  income: "score_income.svg",
  social_security_income: "score_receiveSS.svg",
  retirement_savings: "score_savings.svg",
  monthly_retirement_savings: "score_finances.svg",
  pension_income: "score_pension.svg",
  emergency_fund: "score_emergency.svg",
  investment_style: "score_investment.svg",
  spending_habits: "score_spending.svg",
  health: "score_wellness.svg",
  longevity: "score_family.svg",
  LTC: "score_longtermCare.svg",
  long_term_care: "score_longtermCare.svg",
  who_planned: "score_retirmentPlan.svg",
  inheritance: "score_donate.svg",
  impact: "impact.svg",
  voting_power: "voting_power.svg",
  means_testing: "means_testing.svg",
  your_likelihood: "likelihood.svg",
  final_expense: "longevity.svg",
  score: "score-gauge.svg",
  universal_life: "universal_life.svg",
};
