<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 385.4 220"
    enable-background="new 0 0 385.4 359.2"
    xml:space="preserve"
  >
    <svg:style type="text/css">
      @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600");
      text {
        font-family: "Inter";
        font-weight: bold;
      }
      .bold-text {
        font-weight: bold;
      }
    </svg:style>

    <text
      id="averageScoreValue"
      transform="matrix(1 0 0 1 240 145)"
      :fill="colorScheme === 'dark' ? '#FFFFFF' : '#D0D0D0'"
      font-family="'Inter-Bold'"
      font-size="65.0806px"
      class="bold-text"
    >
      0%
    </text>
    <text
      id="averageScoreValueText"
      transform="matrix(1 0 0 1 240 190)"
      :fill="colorScheme === 'dark' ? '#FFFFFF' : '#D0D0D0'"
      font-family="'Inter-Bold'"
      font-size="18"
      class="bold-text"
    >
      Average Score
    </text>

    <text
      id="prospectScoreValue"
      transform="matrix(1 0 0 1 15 145)"
      :fill="colorScheme === 'dark' ? '#A4D6EC' : '#4188EC'"
      font-family="'Inter-Bold'"
      font-size="77.2388px"
      class="bold-text"
    >
      0%
    </text>
    <text
      id="prospectScoreValueText"
      transform="matrix(1 0 0 1 40 190)"
      :fill="colorScheme === 'dark' ? '#A4D6EC' : '#4188EC'"
      font-family="'Inter-Bold'"
      font-size="18"
      class="bold-text"
    >
      Risk Score
    </text>

    <linearGradient
      id="prospectBarGradient_00000160153878589513903800000005087199854486771598_"
      gradientUnits="userSpaceOnUse"
      x1="-248820.1094"
      y1="35.2507"
      x2="-24740.834"
      y2="35.2507"
      gradientTransform="matrix(-1.719556e-03 0 0 1 -42.3785 0)"
    >
      <stop offset="0" style="stop-color: #e42320" />
      <stop offset="0.3821" style="stop-color: #f8af3c" />
      <stop offset="0.4892" style="stop-color: #f8ce35" />
      <stop offset="0.5822" style="stop-color: #f8e131" />
      <stop offset="0.6462" style="stop-color: #f8e82f" />
      <stop offset="1" style="stop-color: #7fbb48" />
    </linearGradient>
    <rect
      id="prospectBarGradient"
      x="0.2"
      y="24"
      fill="url(#prospectBarGradient_00000160153878589513903800000005087199854486771598_)"
      width="385.3"
      height="22.4"
    />
    <polygon
      id="indicator"
      opacity="0"
      :fill="colorScheme === 'dark' ? '#FFFFFF' : '#24262D'"
      points="0.5,29.9 -5.4,19.6 -11.4,9.4 0.5,9.4 12.3,9.4 6.4,19.6 "
    />
  </svg>
</template>

<script>
import gsap from "gsap";
export default {
  props: {
    colorScheme: {
      type: String,
      default: "dark",
      required: false,
    },
    showAverageBlurb: {
      type: Boolean,
      default: true,
      required: false,
    },
    showProspectAvatar: {
      type: Boolean,
      default: true,
      required: false,
    },
    prospectScore: {
      type: Number,
      default: 81,
      required: true,
    },
    averageScore: {
      type: Number,
      default: 32,
      required: false,
    },
  },
  data() {
    return {
      duration: 1.5,
      ease: "ease-out",
    };
  },
  computed: {
    prospectAvatarComponent() {
      console.log("this.age", this.age);
      console.log("this.gender", this.gender);
      let avatarAge;

      if (this.age <= 55) {
        avatarAge = "55";
      } else if (this.age >= 56) {
        avatarAge = "56";
      }
      let avatarComponentName = this.gender + avatarAge + "Avatar";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}Mobile.vue`
        );
    },
    averageAvatarComponent() {
      let avatarComponentName = this.gender + "AverageAvatarMobile";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}.vue`
        );
    },
  },
  mounted() {
    setTimeout(() => {
      this.runAnimations();
    }, 100);
  },
  methods: {
    runAnimations() {
      const indicator = document.getElementById("indicator");
      // const prospect_avatar = document.getElementById("prospectAvatar");
      const prospect_gradient_bar = document.getElementById(
        "prospectBarGradient"
      );
      const prospect_bar_width = prospect_gradient_bar.getBBox().width;
      const prospect_score_text = document.getElementById("prospectScoreValue");
      const prospect_indicator_location =
        (parseInt(prospect_bar_width) * this.prospectScore) / 100;

      const average_score_text = document.getElementById("averageScoreValue");

      // const average_avatar = document.getElementById("averageAvatar");

      // gsap.set([prospect_avatar, average_avatar], {
      //   scale: 0,
      //   transformOrigin: "50% 50%",
      // });

      const prospectTL = gsap.timeline();

      prospectTL
        // .to([prospect_avatar, average_avatar], {
        //   scale: 1,
        //   transformOrigin: "50% 50%",
        //   duration: 0.25,
        //   ease: "back.out(1.7)",
        // })
        .to(indicator, {
          opacity: 1,
          duration: 0.5,
          ease: this.ease,
        })
        .to(
          indicator,
          {
            x: prospect_indicator_location,
            duration: this.duration - 1,
            ease: this.ease,
          },
          "-=.5"
        );

      const prospectValueTarget = { val: 0 };
      gsap.to(
        prospectValueTarget,
        {
          val: this.prospectScore,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            prospect_score_text.innerHTML =
              prospectValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );

      const averageValueTarget = { val: 0 };
      gsap.to(
        averageValueTarget,
        {
          val: this.averageScore,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            average_score_text.innerHTML =
              averageValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#Layer_1 {
  overflow: visible;
}
</style>
