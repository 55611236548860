<template>
  <v-row>
    <v-col v-for="a in answers" :key="a.id" class="col-4 rs-prospect-answer">
      <!-- <v-card outlined class="mb-2 mr-4 h-100"> -->
      <div class="d-flex flex-column justify-center align-center">
        <v-img
          :src="handleGetSurveyIcon(a.param)"
          max-width="56"
          max-height="56"
        />
        <div class="mt-2 text-center">
          <p
            class="mb-0 text-caption font-weight-bold"
            style="line-height: 1.3"
          >
            {{ a.param.replaceAll("_", "\n").toUpperCase() }}
          </p>

          <p
            class="
              mb-0
              text-center text-body-1
              font-weight-bold
              primary--text
              py-4
              flex-grow-1
            "
          >
            {{ a.value }}
          </p>
        </div>
      </div>
    </v-col>
    <!-- spacer col for css borders -->
    <v-col class="col-4 rs-prospect-answer">&nbsp;</v-col>
  </v-row>
</template>

<script>
import { mapSurveyIcon } from "@/services/surveyIconMapping";

export default {
  props: {
    answers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    handleGetSurveyIcon(param) {
      const iconFileName = mapSurveyIcon(param);
      if (iconFileName) {
        return require("@/assets/surveyIcons/" + iconFileName);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rs-prospect-answer {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  // every third
  &:nth-child(3n + 3) {
    border-right: none;
  }

  // last three
  &:nth-last-child(-n + 3) {
    border-bottom: none;
  }
}
</style>
