<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0.3 23.3 47.8 48"
    xml:space="preserve"
    style="vertical-align: bottom"
  >
    <component
      :is="prospectAvatarComponent"
      :key="gender + '-' + age.toString()"
    />
  </svg>
</template>

<script>
export default {
  props: {
    age: {
      type: Number,
      default: 40,
      required: true,
    },
    gender: {
      type: String,
      default: "Male",
      required: true,
    },
  },
  computed: {
    prospectAvatarComponent() {
      let avatarAge;

      if (this.age <= 55) {
        avatarAge = "55";
      } else if (this.age >= 56) {
        avatarAge = "56";
      }
      let avatarComponentName = this.gender + avatarAge + "Avatar";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}.vue`
        );
    },
  },
};
</script>

<style lang="scss" scoped></style>
